<template>
  <div class="ddns-container">
    <!-- 添加任务 -->
    <div class="dialog-container">
      <el-dialog :title="optDialog === 'query' ? '查询记录' : optDialog === 'add' ? '添加记录' : '编辑记录'"
        :visible="optDialog === 'query' || optDialog === 'add' || optDialog === 'edit'" width="30%"
        :before-close="closeOptDialog" :close-on-click-modal="false">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px">
          <template v-if="optDialog === 'query'">
            <el-form-item label="查询条件" prop="query.condition">
              <el-select v-model="ruleForm.query.condition.selectedIndex" placeholder="请选择" @change="selectCondition">
                <el-option v-for="(item, index) in ruleForm.query.condition.options" :key="item.name" :label="item.text"
                  :value="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              :label="ruleForm.query.condition.selectedIndex !== '' ? ruleForm.query.condition.options[ruleForm.query.condition.selectedIndex].text : ''"
              prop="query.value">
              <el-input
                v-if="ruleForm.query.condition.selectedIndex === 0 || ruleForm.query.condition.selectedIndex === 1"
                v-model="ruleForm.query.value" :placeholder="ruleForm.query.condition.placeholder"></el-input>
              <el-select v-if="ruleForm.query.condition.selectedIndex === 2" v-model="ruleForm.query.role.selectedName"
                placeholder="请选择" @change="selectRole">
                <el-option v-for="item in ruleForm.query.role.options" :key="item.name" :label="item.text"
                  :value="item.name"></el-option>
              </el-select>
              <el-select v-if="ruleForm.query.condition.selectedIndex === 3"
                v-model="ruleForm.query.isOnline.selectedValue" placeholder="请选择" @change="selectOnline">
                <el-option v-for="item in ruleForm.query.isOnline.options" :key="item.name" :label="item.text"
                  :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </template>
          <template v-if="optDialog === 'add'">
            <el-form-item label="类别" prop="add.type">
              <el-select v-model="ruleForm.add.type.selectedIndex" placeholder="请选择" @change="selectType">
                <el-option v-for="item in ruleForm.add.type.options" :key="item.index" :label="item.name"
                  :value="item.index">
                  <span style="display: flex; justify-content: space-between; align-items: center;">
                    <span style="flex: 4; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ item.name
                    }}</span>
                    <span style="flex: 1;"></span>
                    <span
                      style="flex: 5; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; color: #8492a6; font-size: 13px; text-align: right;">{{
                        item.uri }}</span>
                  </span>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="前缀" prop="add.prefix">
              <el-input type="text" v-model="ruleForm.add.prefix"
                placeholder="仅支持三位或两位 IP 前缀，例如：114.95.175、222.65"></el-input>
            </el-form-item>
            <el-form-item label="端口" prop="add.port">
              <el-input type="number" v-model="ruleForm.add.port" placeholder="仅支持单端口，多端口请添加多个任务"></el-input>
            </el-form-item>
            <el-form-item label="资源路径" prop="add.uri">
              <el-input v-model="ruleForm.add.uri" placeholder="/ZHGXTV/Public/json/live_interface.txt"></el-input>
            </el-form-item>
            <el-form-item label="关键词" prop="add.keyword">
              <el-input v-model="ruleForm.add.keyword" placeholder="用&表示和，用|表示或，用!表示非"></el-input>
            </el-form-item>
          </template>
          <template v-if="optDialog === 'edit'">
            <el-form-item label="域名">
              <el-input v-model="ruleForm.edit.domain" disabled></el-input>
            </el-form-item>
            <el-form-item label="内容" prop="edit.content">
              <el-input v-model="ruleForm.edit.content"></el-input>
            </el-form-item>
          </template>
          <el-form-item>
            <el-button type="primary" @click="submitForm">提交</el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>

    <!-- 删除提示 -->
    <div class="dialog-container">
      <el-dialog title="提示" :visible="optDialog === 'delete'" width="30%" :before-close="closeOptDialog"
        :close-on-click-modal="false">
        <span>是否确认删除域名为 {{ ruleForm.delete.domain }} 的 DDNS 记录？</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="optDialog = ''">我再想想</el-button>
          <el-button type="primary" v-throttle="deleteConfirmed">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 扫描提示 -->
    <div class="dialog-container">
      <el-dialog title="提示" :visible="optDialog === 'scan'" width="30%" :before-close="closeOptDialog"
        :close-on-click-modal="false">
        <span>是否重新扫描 {{ ruleForm.scan.content }} 的 DDNS 记录？（扫描结果需要在一个小时之内才能更新显示）</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="optDialog = ''">我再想想</el-button>
          <el-button type="primary" v-throttle="scanConfirmed">确 定</el-button>
        </span>
      </el-dialog>
    </div>

    <!-- 表格容器 -->
    <div class="table-container">
      <!-- 按钮组 -->
      <div class="opt-container">
        <!-- 查询按钮 -->
        <el-button @click="query">查询</el-button>
        <!-- 添加按钮 -->
        <el-button type="primary" @click="addDDNS">添加</el-button>
        <!-- 导出按钮 -->
        <el-button type="success" @click="copyAll">导出</el-button>
      </div>

      <!-- 表格内容 -->
      <el-table v-loading="loading" element-loading-text="加载中，请稍后..." :data="tableData" border>
        <!-- 表格列定义 -->
        <el-table-column prop="no" label="序号" width="50" header-align="center" align="center"
          :resizable=false></el-table-column>
        <el-table-column prop="domain" label="域名" header-align="center" align="center" :resizable=false
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="content" label="内容" width="150" header-align="center" align="center"
          :resizable=false></el-table-column>
        <el-table-column prop="createdOn" label="创建时间" width="180" header-align="center" align="center"
          :resizable=false></el-table-column>
        <el-table-column prop="modifiedOn" label="更新时间" width="180" header-align="center" align="center"
          :resizable=false></el-table-column>
        <el-table-column prop="isOnline" label="是否在线" width="80" header-align="center" align="center" :resizable=false>
          <template slot-scope="scope">
            <el-tag :type="scope.row.isOnline === 1 ? 'success' : 'danger'" effect="dark">{{ scope.row.isOnline === 1 ?
              '在线' : '离线' }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="count" label="在线人数" width="80" header-align="center" align="center"
          :resizable=false></el-table-column>
        <el-table-column label="操作" width="300" header-align="center" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="editDDNS(scope.$index, scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="deleteDDNS(scope.$index, scope.row)">删除</el-button>
            <el-button size="mini" type="primary" @click="scanDDNS(scope.$index, scope.row)">扫描</el-button>
            <el-button size="mini" type="success" @click="copy(scope.$index, scope.row)">复制</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页容器 -->
      <div class="pagination-container">
        <!-- 分页组件 -->
        <el-pagination @size-change="changeSize" @current-change="changePage" :current-page="page"
          :page-sizes="[10, 100, 500]" :page-size="pageSize" :total="total"
          layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import subService from '@/api/services/subService';
export default {
  name: 'DDNS',
  data() {
    var validateValue = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('不能为空'));
      } else {
        callback();
      }
    };

    var validatePrefix = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入 IP 前缀'));
      } else if (!/^(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)){1,2}$/.test(value)) {
        callback(new Error('前缀值必须是2位或者3位的合法 IP 地址'));
      } else {
        callback();
      }
    };

    var validatePort = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入端口'));
      } else if (value < 0 || value > 65535) {
        callback(new Error('端口范围应在0到65535之间'));
      } else {
        callback();
      }
    };

    var validateUri = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入资源路径'));
      } else if (!value.startsWith('/')) {
        callback(new Error('资源路径必须以/开头'));
      } else if (/[,，]/.test(value)) {
        callback(new Error('资源路径不能包含逗号'));
      } else {
        callback();
      }
    };

    var validateContent = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入 IP 地址'));
      } else if (!/^([0-9]{1,3}\.){3}[0-9]{1,3}$/.test(value)) {
        callback(new Error('内容必须是完整合法的 IP 地址'));
      } else {
        callback();
      }
    };

    return {
      loading: false, // 控制页面的加载状态
      role: {
        name: 'guest',
        text: '游客',
        level: 1
      }, // 用户默认角色
      ruleForm: {
        query: {
          name: 'domain',
          value: '',
          condition: {
            selectedIndex: 0,
            placeholder: '支持模糊查询',
            options: [{
              name: 'domain',
              text: '域名',
              placeholder: '支持模糊查询',
            }, {
              name: 'content',
              text: '内容',
              placeholder: '支持模糊查询',
            }]
          },
        },
        add: {
          type: {
            selectedIndex: 0,
            options: [{
              index: 0,
              name: '自定义',
              uri: '自定义',
              keyword: '',
              remark: [],
            }, {
              index: 1,
              name: '酒店-智能桌面',
              uri: '/iptv/live/1000.json',
              keyword: 'CCTV|翡翠|澳视|澳視|台视|台視',
              remark: ['酒店源', '直播源', '电视源'],
            }, {
              index: 2,
              name: '酒店-智慧光迅',
              uri: '/ZHGXTV/Public/json/live_interface.txt',
              keyword: 'CCTV|翡翠|澳视|澳視|台视|台視',
              remark: ['酒店源', '直播源', '电视源'],
            }, {
              index: 3,
              name: '酒店-秒开',
              uri: '/api/post?item=itv_traffic',
              keyword: 'CCTV|翡翠|澳视|澳視|台视|台視',
              remark: ['酒店源', '直播源', '电视源'],
            }, {
              index: 4,
              name: '酒店-华视美达',
              uri: '/newlive/manager/index.php',
              keyword: '华视美达',
              remark: ['酒店源', '直播源', '电视源'],
            }, {
              index: 5,
              name: '酒店-维盟',
              uri: '/dpl/PotPlayerMini.dpl',
              keyword: 'CCTV|翡翠|澳视|澳視|台视|台視',
              remark: ['酒店源', '直播源', '电视源'],
            }, {
              index: 6,
              name: '酒店-腾龙',
              uri: '/login?redirect=%2Fmanage',
              keyword: '<title>TamronOS IPTV系统</title>',
              remark: ['酒店源', '直播源', '电视源'],
            }, {
              index: 7,
              name: '酒店-威堡',
              uri: '/admin/user/login.html',
              keyword: '<title>威堡IPTV电视管理后台</title>',
              remark: ['酒店源', '直播源', '电视源'],
            }, {
              index: 8,
              name: '酒店-毒盒',
              uri: '/',
              keyword: '<meta name="author" content="luo2888" />',
              remark: ['酒店源', '直播源', '电视源'],
            }, {
              index: 9,
              name: '组播-udpxy',
              uri: '/status',
              keyword: 'udpxy and udpxrec are Copyright',
              remark: ['组播源', '直播源', '电视源'],
            }, {
              index: 10,
              name: '地波-MuMuDVB',
              uri: '/channels_list.html',
              keyword: 'MuMuDVB',
              remark: ['地波源', '直播源', '电视源'],
            }, {
              index: 11,
              name: '地波-TVH',
              uri: '/playlist/ticket/channels',
              keyword: 'CCTV|翡翠|澳视|澳視|台视|台視',
              remark: ['地波源', '直播源', '电视源'],
            }, {
              index: 12,
              name: 'VIP视频解析',
              uri: '/jiexi/?url=',
              keyword: '视频URL地址不能为空|视频URL地址&接口!水印',
              remark: ['VIP视频解析源', '流媒体源', '电视源'],
            }, {
              index: 13,
              name: 'CDN加速节点',
              uri: '/',
              keyword: '<center>cloudflare</center>',
              remark: ['Cloudflare CDN加速'],
            }]
          },
          prefix: '',
          port: '',
          uri: '',
          keyword: ''
        },
        edit: {
          domain: '',
          content: '',
        },
        delete: {
          domain: '',
        },
        scan: {
          content: '',
        }
      },
      rules: {
        'query.condition': [
          { required: true, trigger: 'blur' }
        ],
        'query.value': [
          { required: true, validator: validateValue, trigger: 'blur' }
        ],
        'add.type': [
          { required: true, trigger: 'blur' }
        ],
        'add.prefix': [
          { required: true, validator: validatePrefix, trigger: 'blur' }
        ],
        'add.port': [
          { required: true, validator: validatePort, trigger: 'blur' }
        ],
        'add.uri': [
          { required: true, validator: validateUri, trigger: 'blur' }
        ],
        'edit.content': [
          { required: true, validator: validateContent, trigger: 'blur' }
        ]
      },
      popoverVisible: false,
      optDialog: '',
      index: 0,
      // 表格初始数据
      tableData: [
      ],
      // 表格当前页码
      page: 1,
      // 表格页大小
      pageSize: 10,
      // 表格总记录
      total: 0,
    };
  },
  mounted() {
    // 初始化时加载数据
    this.getData();

    // 读取角色信息，若不存在则使用默认角色
    this.role = JSON.parse(localStorage.getItem('role')) || { name: 'guest', text: '游客', level: 1 };
  },
  methods: {
    // 获取数据
    getData() {
      // 表格加载中
      this.loading = true;

      // 准备请求参数
      const params = {
        page: this.page,         // 当前页码
        page_size: this.pageSize // 页面大小
      };

      // 通过 subService 获取动态DNS列表
      subService.getDDNSList(params).then(response => {
        // 设置总记录数
        this.total = response.data.total;
        // 构建表格数据
        this.tableData = response.data.list.map((item, index) => {
          // 返回表格数据对象
          return {
            no: (index + 1), // 序号
            domain: item.domain, // 域名
            content: item.content, // 内容
            createdOn: item.created_on, // 创建时间
            modifiedOn: item.modified_on, // 更新时间
            url: item.url, // 地址
            isOnline: item.is_online, // 是否在线
            count: item.count, // 在线客户端数量
          };
        });
      })
        .catch(error => {
          console.error(error); // 捕获并输出错误
        })
        .finally(() => {
          // 表格加载完毕
          this.loading = false;
        });
    },
    // 当每页条数变化时
    changeSize(pageSize) {
      this.page = 1;
      this.pageSize = pageSize;
      this.getData();
    },
    // 当前页码变化时
    changePage(page) {
      this.page = page;
      this.getData();
    },
    selectType(index) {
      if (index) {
        this.ruleForm.add.uri = this.ruleForm.add.type.options[index].uri;
        this.ruleForm.add.keyword = this.ruleForm.add.type.options[index].keyword;
      } else {
        this.ruleForm.add.uri = '';
        this.ruleForm.add.keyword = '';
      }
      this.$refs.ruleForm.clearValidate(); // 清除所有字段的验证状态
    },
    selectCondition(index) {
      this.ruleForm.query.value = '';

      const option = this.ruleForm.query.condition.options[index];
      this.ruleForm.query.condition.selectedIndex = index;

      // 清除所有字段的验证状态
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });

      if (index === 0 || index === 1) {
        this.ruleForm.query.condition.placeholder = option['placeholder'];
      } else if (index === 2) {
        this.ruleForm.query.role.selectedName = 'premium';
        this.ruleForm.query.value = 'premium';
      } else if (index === 3) {
        this.ruleForm.query.isOnline.selectedValue = 1;
        this.ruleForm.query.value = 1;
      }
      this.ruleForm.query.name = this.ruleForm.query.condition.options[index].name;
    },
    addDDNS() {
      this.optDialog = 'add';
      // 打开添加对话框时重置数据
      this.resetForm();
    },
    closeOptDialog() {
      // 关闭窗口
      this.optDialog = '';
    },
    showInput() {
      this.ruleForm.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.optDialog === 'query') {
            // 返回包含特定字符串的数组元素
            this.tableData = this.tableData.filter((item) => {
              const propertyName = this.ruleForm.query.name; // 获取属性名
              const queryValue = this.ruleForm.query.value;  // 获取查询值
              return item[propertyName].includes(queryValue); // 检查属性值是否包含查询值
            });
            // 关闭窗口
            this.optDialog = '';
          } else if (this.optDialog === 'add') {
            // 准备请求参数
            const params = {
              prefix: this.ruleForm.add.prefix,
              port: this.ruleForm.add.port,
              uri: this.ruleForm.add.uri,
              keyword: this.ruleForm.add.keyword
            };
            subService.addDDNS(params).then(response => {
              // 输出成功提示
              this.$message.success('新记录将会在一个小时之内生效，请耐心等待！');
            })
              .catch(error => {
                console.error(error); // 捕获并输出错误
              })
              .finally(() => {
                // 关闭窗口
                this.optDialog = '';
              });
          } else if (this.optDialog === 'edit') {
            // 获取当前表格行的内容
            const content = this.tableData[this.index].content;

            // 获取编辑表单中的域名和新内容
            const domain = this.ruleForm.edit.domain;
            const new_content = this.ruleForm.edit.content;

            // 检查内容是否有变化
            if (content === new_content) {
              this.optDialog = ''; // 关闭窗口
              this.$message.warning('内容没有任何变化，无需更新！'); // 输出警告提示
              return;
            }

            // 准备请求参数
            const params = {
              domain: domain,
              content: content,
            };
            subService.updateDDNS(params).then(response => {
              // 获取数据
              this.getData();
              // 输出成功提示
              this.$message.success(response.message);
            })
              .catch(error => {
                console.error(error); // 捕获并输出错误
              })
              .finally(() => {
                // 关闭窗口
                this.optDialog = '';
                // 重置对话框数据
                this.resetForm();
              });
          }
        } else {
          this.$message.error('请检查表单数据！');
          return false;
        }
      });
    },
    resetForm() {
      // 清除所有字段的验证状态
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });

      // 重置数据
      if (this.optDialog === 'query') {
        this.ruleForm.query.name = 'domain';
        this.ruleForm.query.value = '';
        this.ruleForm.query.condition.selectedIndex = 0;
        this.ruleForm.query.condition.placeholder = '支持模糊查询';
      } else if (this.optDialog === 'add') {
        this.ruleForm.add.prefix = '';
        this.ruleForm.add.port = '';
        this.ruleForm.add.type.selectedIndex = 9;
        const option = this.ruleForm.add.type.options[this.ruleForm.add.type.selectedIndex];
        this.ruleForm.add.uri = option.uri;
        this.ruleForm.add.keyword = option.keyword;
      }
    },
    editDDNS(index, row) {
      // 记录当前编辑任务的索引
      this.index = index;

      // 打开编辑对话框
      this.optDialog = 'edit';

      // 设置表单的域名、内容字段
      this.ruleForm.edit.domain = row.domain;
      this.ruleForm.edit.content = row.content;
    },
    deleteDDNS(index, row) {
      // 设置当前删除任务的索引
      this.index = index;

      // 打开删除确认框
      this.optDialog = 'delete';

      // 设置表单的域名字段
      this.ruleForm.delete.domain = row.domain;
    },
    deleteConfirmed() {
      // 准备请求参数
      const params = {
        domain: this.ruleForm.delete.domain
      };
      subService.deleteDDNS(params).then(response => {
        // 获取数据
        this.getData();
        // 输出成功提示
        this.$message.success(response.message);
      })
        .catch(error => {
          console.error(error); // 捕获并输出错误
        })
        .finally(() => {
          // 关闭窗口
          this.optDialog = '';
        });
    },
    scanDDNS(index, row) {
      // 设置当前DDNS的索引
      this.index = index;

      if (row.isOnline === 1) {
        // 输出在线提示
        this.$message.warning('该源是在线的，无需扫描！');
        return;
      }

      // 打开扫描确认框
      this.optDialog = 'scan';

      // 设置表单的域名字段
      this.ruleForm.scan.content = row.content;
    },
    scanConfirmed() {
      /**
       * 根据类型获取对应的 URI 和关键字对象
       * 
       * @param {string} type - 要查询的类型
       * @returns {object} - 包含 uri 和 keyword 的对象，如果类型不存在则返回空对象
       */
      function _getTypeMap(type) {
        // 定义类型与其对应的 URI 和关键字的映射关系
        const typeUriKeywordMap = {
          'udpxy': { uri: '/status', keyword: 'udpxy and udpxrec are Copyright' },
          'msd': { uri: '/stat', keyword: 'Multi stream&connections online' },
          'mq': { uri: '/api/post?item=itv_traffic', keyword: 'CCTV1' },
          'zhgx': { uri: '/ZHGXTV/Public/json/live_interface.txt', keyword: 'CCTV1' },
          'znzm': { uri: '/iptv/live/1000.json', keyword: 'CCTV1' },
          'hsmd': { uri: '/newlive/manager/index.php', keyword: 'CCTV1' }
        };

        // 返回对应类型的 URI 和关键字，若找不到则返回默认值
        return typeUriKeywordMap[type] || { uri: '/', keyword: '' };
      }
      /**
       * 提取域名中的类型、前缀和端口信息
       * 
       * @param {string} domain - 域名，如 "beijing_unicom_msd_114_242_12_4022_1.isus.cc"
       * @returns {object} - 包含 type, prefix 和 port 的对象
       */
      function _getDomainInfo(domain) {
        // 定义正则表达式
        const regex = /[a-zA-Z]+_[a-zA-Z]+_([a-zA-Z]+)_([0-9_]+)_([0-9]+)_[0-9]+\./;

        // 执行正则匹配
        const matches = domain.match(regex);
        // 如果匹配到了，解构数组并返回对象
        if (matches) {
          const [, type, prefix, port] = matches;

          // 将 prefix 中的下划线替换为点号
          const formattedPrefix = prefix.replace(/_/g, '.');

          // 获取对应类型的 URI 和关键字信息
          const typeInfo = _getTypeMap(type);

          // 返回完整的信息对象
          return {
            prefix: formattedPrefix,
            port: port,
            uri: typeInfo.uri,
            keyword: typeInfo.keyword
          };
        } else {
          // 如果没有匹配到，返回空对象或者根据需求返回 null 或者其他默认值
          return {};
        }
      }

      // 获取域名信息
      const domainInfo = _getDomainInfo(this.tableData[this.index].domain);
      // 准备请求参数
      const params = {
        prefix: domainInfo.prefix,
        port: domainInfo.port,
        uri: domainInfo.uri,
        keyword: domainInfo.keyword
      };
      subService.addDDNS(params).then(response => {
        // 输出成功提示
        this.$message.success('新记录将会在一个小时之内生效，请耐心等待！');
      })
        .catch(error => {
          console.error(error); // 捕获并输出错误
        })
        .finally(() => {
          // 关闭窗口
          this.optDialog = '';
        });
    },
    query() {
      // 打开查询对话框
      this.optDialog = 'query';
      // 重置数据
      this.resetForm();
    },
    copyAll() {
      if (this.tableData.length !== 0) {
        // 构建复制内容
        let copyContent = '';
        // 遍历列表，添加每个直播源的信息
        this.tableData.forEach((item, index) => {
          // 将选定的直播源名称和URL添加到复制内容中
          copyContent += `${item.domain} => ${item.content}\n`;
        });
        // 调用全局的复制服务
        this.$clipboard(copyContent).then(() => {
          this.$message({
            message: '已导出到剪贴板',
            type: 'success',
            duration: 1000
          });
        })
          .catch(() => {
            this.$message.error('导出失败');
          });
      } else {
        this.$message.error('暂无数据导出');
      }
    },
    copy(index, row) {
      // 构建复制内容
      const copyContent = `${row.domain} => ${row.content}`;
      // 调用全局的复制服务
      this.$clipboard(copyContent).then(() => {
        this.$message({
          message: '已复制到剪贴板',
          type: 'success',
          duration: 1000
        });
      })
        .catch(() => {
          this.$message.error('复制失败');
        });
    },
  },
}
</script>

<style scoped lang="scss">
.ddns-container ::v-deep {
  .dialog-container {
    .el-dialog__wrapper {
      .el-form-item__content {

        .el-select {
          width: 100%;
        }
      }
    }
  }

  /* 移动端样式（小于 1200px） */
  @media screen and (max-width: 1200px) {
    .dialog-container {
      .el-dialog {
        width: 90% !important;
      }
    }

    /* 表格最大高度 */
    .el-table {
      max-height: 500px;
    }

    /* 分页组件横向滚动样式 */
    .pagination-container {
      overflow-x: scroll;
    }
  }

  /* PC端样式（大于等于 1200px） */
  @media screen and (min-width: 1200px) {

    /* 表格最大高度 */
    .el-table {
      max-height: 380px;
    }
  }

  /* 表格相关样式 */
  .table-container {
    padding: 0 20px;

    /* 操作组件样式 */
    .opt-container {
      margin: 10px 0;
      text-align: right;
    }

    /* 分页组件样式 */
    .pagination-container {
      margin: 30px 0;
    }

    /* 表格内整体样式 */
    .el-table {
      position: relative;
      overflow-y: auto;
      /* 表格外边框颜色 */
      border: 2px solid #4682B4;

      /* 去掉表格底部多余的白色边框 */
      &::before {
        height: 0;
      }

      /* 设置表格遮罩为全屏 */
      .el-loading-mask {
        position: fixed;
      }

      /* 表格头部样式 */
      .el-table__header-wrapper {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: #FFFFFFAA;
      }

      /* 表头单元格边框颜色 */
      .el-table__header-wrapper thead th {
        border-right: 2px solid #4682B4 !important;
        border-bottom: 2px solid #4682B4 !important;
      }

      /* 去除表头最后一个单元格右边框颜色 */
      .el-table__header-wrapper thead th:nth-last-child(2) {
        border-right: none !important;
      }

      /* 内部单元格边框颜色 */
      .el-table__body-wrapper tbody tr td,
      .el-table__fixed tbody tr td {
        border-right: 2px solid #4682B4 !important;
        border-bottom: 2px solid #4682B4 !important;
      }

      /* 去除内部单元格最后一个单元格右边框颜色 */
      .el-table__body-wrapper tbody tr td:nth-last-child(1),
      .el-table__fixed tbody tr td:nth-last-child(1) {
        border-right: none !important;
      }

      /* 去除最后一行内部单元格下边框颜色 */
      .el-table__body-wrapper tbody tr:nth-last-child(1) td,
      .el-table__fixed tbody tr:nth-last-child(1) td {
        border-bottom: none !important;
      }

      /* 表格内背景颜色透明 */
      th.el-table__cell,
      tr,
      td {
        background-color: transparent;
      }
    }

    .el-table--border {

      /* 去掉表格右侧多余的白色边框 */
      &::after {
        width: 0;
      }
    }

    /* 最外层透明 */
    .el-table,
    .el-table__expanded-cell {
      background-color: transparent;
    }
  }
}
</style>
