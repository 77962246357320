import { apiInstance, API_URLS } from '@/api';
import md5 from 'js-md5';

// 应用标识符
const APP_ID = 'qwtc_web';

const userService = {
  /**
   * 存储认证信息
   * @param {Object} auth - 认证信息的参数对象
   */
  _storeAuth(auth) {
    // 存储访问令牌和刷新令牌到本地存储（LocalStorage）
    localStorage.setItem('token', auth.token); // 存储编码后的 token
    localStorage.setItem('expires_time', auth.expiresTime); // 存储访问令牌的过期时间
    localStorage.setItem('refresh_token', auth.refreshToken); // 存储刷新令牌
    localStorage.setItem('refresh_expires_time', auth.refreshExpiresTime); // 存储刷新令牌的过期时间
    localStorage.setItem('uid', auth.uid); // 存储用户ID
    localStorage.setItem('role', JSON.stringify(auth.role)); // 存储角色
  },

  /**
   * 生成签名
   * @param {Object} params - 待签名的参数对象
   * @param {string} appSecret - 应用密钥，默认为 '123456'
   * @returns {string} - 返回生成的签名字符串
   */
  _sign(params = {}, appSecret = '123456') {
    // 对参数对象按键名进行排序
    const sortedParams = Object.fromEntries(Object.entries(params).sort());

    // 将应用密钥添加到参数对象中
    sortedParams.key = appSecret;

    // 将参数对象转换成查询字符串
    const queryString = new URLSearchParams(sortedParams).toString();

    // 对查询字符串进行 URL 解码
    const decodedQueryString = decodeURIComponent(queryString);

    // 计算 URL 解码后的查询字符串的 MD5 哈希值，并转换为小写字母形式
    const md5Signature = md5(decodedQueryString).toLowerCase();

    return md5Signature;
  },

  /**
   * 生成签名密钥字符串。
   * @returns {string} 生成的签名密钥字符串
   */
  _getKey() {
    let keyPairs = [
      'l', '3', // 字符 'l' 和目标索引 3
      '8', '0', // 字符 '8' 和目标索引 0
      'o', '1', // 字符 'o' 和目标索引 1
      '^', '4', // 字符 '^' 和目标索引 4
      '8', '7', // 字符 '8' 和目标索引 7
      '8', '2', // 字符 '8' 和目标索引 2
      'e', '6', // 字符 'e' 和目标索引 6
      '6', '5', // 字符 '6' 和目标索引 5
    ];

    let result = []; // 用于存储最终字符的数组
    // 遍历 keyPairs 数组，步进值为 2
    for (let i = 0; i < keyPairs.length; i += 2) {
      const value = keyPairs[i]; // 获取字符
      const index = parseInt(keyPairs[i + 1], 10); // 获取该字符对应的索引，转换为数字
      result[index] = value; // 将字符放置到结果数组的正确位置上
    }

    return result.join(''); // 将结果数组转换为字符串并返回
  },

  // 登录
  async login(data) {
    // 生成一个简单的随机数作为nonce
    const nonce = Math.random().toString(36).substring(2, 15);
    // 获取当前时间戳（秒）
    const timestamp = Math.floor(Date.now() / 1000);

    // 准备请求参数
    const params = {
      appid: APP_ID,
      ...data,
      nonce: nonce,
      timestamp: timestamp.toString(),
    };
    // 获取密钥
    const key = this._getKey();
    // 将签名添加到请求参数中
    params.sign = this._sign(params, key);

    try {
      const response = await apiInstance.post(API_URLS.LOGIN, params);
      // 从响应中获取必要的信息
      const appid = response.data.client.appid;
      const accessToken = response.data.access_token;
      const uid = response.data.client.uid;
      const role = {
        name: response.data.client.role_name,
        text: response.data.client.role_text,
        level: response.data.client.role_level,
      };

      // 将获取的信息编码为 token 字符串
      const token = btoa(`${appid}:${accessToken}:${uid}`);

      // 获取访问令牌和刷新令牌的过期时间
      const expiresTime = response.data.expires_time;
      const refreshToken = response.data.refresh_token;
      const refreshExpiresTime = response.data.refresh_expires_time;

      // 存储认证信息
      this._storeAuth({
        token,
        expiresTime,
        refreshToken,
        refreshExpiresTime,
        uid,
        role,
      });
      return response;
    } catch (error) {
      // 抛出错误
      throw error;
    }
  },

  // 刷新token
  async refreshToken() {
    // 从 localStorage 中获取 uid、refresh_token
    const uid = localStorage.getItem('uid');
    const refreshToken = localStorage.getItem('refresh_token');

    // 检查是否存在 uid
    if (!uid) {
      return Promise.reject('没有找到uid');
    }

    // 检查是否存在 refresh_token
    if (!refreshToken) {
      return Promise.reject('没有找到refresh_token');
    }

    // 准备请求参数
    const params = {
      uid: uid,
      refresh_token: refreshToken,
    };

    try {
      const response = await apiInstance.post(API_URLS.REFRESH_TOKEN, params, { quiet: true });
      // 从响应中获取必要的信息
      const appid = response.data.client.appid;
      const access_token = response.data.access_token;
      const uid = response.data.client.uid;
      const role = {
        name: response.data.client.role_name,
        text: response.data.client.role_text,
        level: response.data.client.role_level,
      };

      // 将获取的信息编码为 token 字符串
      const token = btoa(`${appid}:${access_token}:${uid}`);

      // 获取访问令牌和刷新令牌的过期时间
      const expiresTime = response.data.expires_time;
      const refreshToken = response.data.refresh_token;
      const refreshExpiresTime = response.data.refresh_expires_time;

      // 存储认证信息
      this._storeAuth({
        token,
        expiresTime,
        refreshToken,
        refreshExpiresTime,
        uid,
        role,
      });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  },

  // 发送验证码
  sendCode(data) {
    return apiInstance.post(API_URLS.CODE, data, {
      quiet: true
    });
  },

  // 注册
  register(data) {
    return apiInstance.post(API_URLS.REGISTER, data);
  },

  // 找回密码
  reset(data) {
    return apiInstance.post(API_URLS.RESET, data);
  },

  // 退出登录
  logout() {
    return apiInstance.get(API_URLS.LOGOUT, {
      headers: {
        requiresAuth: true
      }
    });
  },

  // 获取关键词信息
  getKeywords() {
    return apiInstance.get(API_URLS.KEYWORDS, {
      timeout: 10 * 1000,
    });
  },

  // 获取搜索结果信息
  getSearchResult(data) {
    return apiInstance.get(API_URLS.SEARCH, {
      params: data,
      headers: {
        requiresAuth: true
      },
      timeout: 10 * 1000,
    });
  },

  // 获取解析结果信息
  getParseResult(data) {
    // 获取普通解析结果信息
    if (data.type === 'basic') {
      delete data.type; // 删除数据中的 type 属性
      return apiInstance.get(API_URLS.PARSEBASIC, {
        params: data,
        headers: {
          requiresAuth: true
        },
        timeout: 10 * 1000, // 设定超时时间为10秒钟
        errorMessageDuration: 3 * 1000 // 设定错误消息显示时间
      });
    // 获取高级解析结果信息
    } else if (data.type === 'plus') {
      delete data.type; // 删除数据中的 type 属性
      return apiInstance.get(API_URLS.PARSEPLUS, {
        params: data,
        headers: {
          requiresAuth: true
        },
        timeout: 120 * 1000, // 设定超时时间为2分钟
        errorMessageDuration: 3 * 1000 // 设定错误消息显示时间
      });
    }
  },

  // 获取用户信息
  getUserInfo() {
    return apiInstance.get(API_URLS.USER_INFO, {
      headers: {
        requiresAuth: true
      }
    });
  },

  // 获取用户列表
  listUsers(data) {
    return apiInstance.get(API_URLS.LIST_USERS, {
      params: data,
      headers: {
        requiresAuth: true
      }
    });
  },

  // 编辑用户
  editUser(data) {
    return apiInstance.post(API_URLS.UPDATE_USER, data, {
      headers: {
        requiresAuth: true
      }
    });
  },

  // 删除用户
  deleteUser(data) {
    return apiInstance.post(API_URLS.DELETE_USER, data, {
      headers: {
        requiresAuth: true
      }
    });
  },
};

export default userService;
